<template>
    <div>
        <div class="sub-title">基础信息</div>
        <el-form :inline="true" label-width="130px" :model="form" class="demo-form-inline">
            <el-row>
                <el-col :span="6">
                    <el-form-item label="入园涉农加工企业(家)" label-width="180px">
                        <el-input v-model="form.processingEnterprisesCount"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="年度总产值(万元)">
                        <el-input v-model="form.iotEquipmentCount"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <div class="line"></div>
            <el-row>
                <el-button type="primary" style="min-width: 88px;" @click="add">新增</el-button>
                    <el-table border :data="tableData" class="mt20">
                        <el-table-column prop="companyName" label="公司名称"> </el-table-column>
                        <el-table-column prop="xAxis" label="经度"> </el-table-column>
                        <el-table-column prop="yAxis" label="纬度"> </el-table-column>
                        <el-table-column prop="remark" label="公司描述">
            
                        </el-table-column>
                        <el-table-column prop="name" label="操作" align="center">
                            <template slot-scope="scope">
                                <el-button type="text" @click="edit(scope.row)" size="small">修改</el-button>
                                <el-button type="text" @click="remove(scope.row.id)" size="small">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
        
            </el-row>
            <div class="line"></div>
            <el-row>
                <el-form-item label="公司名称1">
                    <el-input style="width: 550px;" v-model="form.enterprises1Name"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-col :span="6">
                    <el-form-item label="属性名称1">
                        <el-input v-model="form.enterprises1Attr1"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="属性值1">
                        <el-input v-model="form.enterprises1Value1"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="单位1">
                        <el-input v-model="form.enterprises1Unit1"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="6">
                    <el-form-item label="属性名称2">
                        <el-input v-model="form.enterprises1Attr2"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="属性值2">
                        <el-input v-model="form.enterprises1Value2"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="单位2">
                        <el-input v-model="form.enterprises1Unit2"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="6">
                    <el-form-item label="属性名称3">
                        <el-input v-model="form.enterprises1Attr3"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="属性值3">
                        <el-input v-model="form.enterprises1Value3"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="单位3">
                        <el-input v-model="form.enterprises1Unit3"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="6">
                    <el-form-item label="属性名称4">
                        <el-input v-model="form.enterprises1Attr4"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="属性值4">
                        <el-input v-model="form.enterprises1Value4"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="单位4">
                        <el-input v-model="form.enterprises1Unit4"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-form-item label="公司简介">
                    <el-input style="width: 670px;" type="textarea" v-model="form.enterprises1Desc"></el-input>
                </el-form-item>
            </el-row>
            <div class="line"></div>
            <el-row>
                <el-form-item label="公司名称2">
                    <el-input style="width: 550px;" v-model="form.enterprises2Name"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-col :span="6">
                    <el-form-item label="属性名称1">
                        <el-input v-model="form.enterprises2Attr1"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="属性值1">
                        <el-input v-model="form.enterprises2Value1"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="单位1">
                        <el-input v-model="form.enterprises2Unit1"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="6">
                    <el-form-item label="属性名称2">
                        <el-input v-model="form.enterprises2Attr2"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="属性值2">
                        <el-input v-model="form.enterprises2Value2"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="单位2">
                        <el-input v-model="form.enterprises2Unit2"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="6">
                    <el-form-item label="属性名称3">
                        <el-input v-model="form.enterprises2Attr3"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="属性值3">
                        <el-input v-model="form.enterprises2Value3"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="单位3">
                        <el-input v-model="form.enterprises2Unit3"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="6">
                    <el-form-item label="属性名称4">
                        <el-input v-model="form.enterprises2Attr4"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="属性值4">
                        <el-input v-model="form.enterprises2Value4"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="单位4">
                        <el-input v-model="form.enterprises2Unit4"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-form-item label="公司简介">
                    <el-input style="width: 670px;" type="textarea" v-model="form.enterprises2Desc"></el-input>
                </el-form-item>
            </el-row>
            <div class="line"></div>
            <el-row>
                <el-form-item label="公司名称3">
                    <el-input style="width: 550px;" v-model="form.enterprises3Name"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-col :span="6">
                    <el-form-item label="属性名称1">
                        <el-input v-model="form.enterprises3Attr1"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="属性值1">
                        <el-input v-model="form.enterprises3Value1"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="单位1">
                        <el-input v-model="form.enterprises3Unit1"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="6">
                    <el-form-item label="属性名称2">
                        <el-input v-model="form.enterprises3Attr2"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="属性值2">
                        <el-input v-model="form.enterprises3Value2"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="单位2">
                        <el-input v-model="form.enterprises3Unit2"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="6">
                    <el-form-item label="属性名称3">
                        <el-input v-model="form.enterprises3Attr3"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="属性值3">
                        <el-input v-model="form.enterprises3Value3"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="单位3">
                        <el-input v-model="form.enterprises3Unit3"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="6">
                    <el-form-item label="属性名称4">
                        <el-input v-model="form.enterprises3Attr4"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="属性值4">
                        <el-input v-model="form.enterprises3Value4"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="单位4">
                        <el-input v-model="form.enterprises3Unit4"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-form-item label="公司简介">
                    <el-input style="width: 670px;" type="textarea" v-model="form.enterprises3Desc"></el-input>
                </el-form-item>
            </el-row>
            <div class="line"></div>

            <el-row>
                <el-form-item label="公司名称4">
                    <el-input style="width: 550px;" v-model="form.enterprises4Name"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-col :span="6">
                    <el-form-item label="属性名称1">
                        <el-input v-model="form.enterprises4Attr1"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="属性值1">
                        <el-input v-model="form.enterprises4Value1"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="单位1">
                        <el-input v-model="form.enterprises4Unit1"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="6">
                    <el-form-item label="属性名称2">
                        <el-input v-model="form.enterprises4Attr2"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="属性值2">
                        <el-input v-model="form.enterprises4Value2"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="单位2">
                        <el-input v-model="form.enterprises4Unit2"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="6">
                    <el-form-item label="属性名称3">
                        <el-input v-model="form.enterprises4Attr3"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="属性值3">
                        <el-input v-model="form.enterprises4Value3"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="单位3">
                        <el-input v-model="form.enterprises4Unit3"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="6">
                    <el-form-item label="属性名称4">
                        <el-input v-model="form.enterprises4Attr4"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="属性值4">
                        <el-input v-model="form.enterprises4Value4"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="单位4">
                        <el-input v-model="form.enterprises4Unit4"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-form-item label="公司简介">
                    <el-input style="width: 670px;" type="textarea" v-model="form.enterprises4Desc"></el-input>
                </el-form-item>
            </el-row>
            <div class="line"></div>


            <el-row>
                <el-form-item label="公司名称5">
                    <el-input style="width: 550px;" v-model="form.enterprises5Name"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-col :span="6">
                    <el-form-item label="属性名称1">
                        <el-input v-model="form.enterprises5Attr1"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="属性值1">
                        <el-input v-model="form.enterprises5Value1"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="单位1">
                        <el-input v-model="form.enterprises5Unit1"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="6">
                    <el-form-item label="属性名称2">
                        <el-input v-model="form.enterprises5Attr2"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="属性值2">
                        <el-input v-model="form.enterprises5Value2"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="单位2">
                        <el-input v-model="form.enterprises5Unit2"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="6">
                    <el-form-item label="属性名称3">
                        <el-input v-model="form.enterprises5Attr3"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="属性值3">
                        <el-input v-model="form.enterprises5Value3"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="单位3">
                        <el-input v-model="form.enterprises5Unit3"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="6">
                    <el-form-item label="属性名称4">
                        <el-input v-model="form.enterprises5Attr4"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="属性值4">
                        <el-input v-model="form.enterprises5Value4"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="单位4">
                        <el-input v-model="form.enterprises5Unit4"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-form-item label="公司简介">
                    <el-input style="width: 670px;" type="textarea" v-model="form.enterprises5Desc"></el-input>
                </el-form-item>
            </el-row>
            <div class="line"></div>

            <el-row>
                <el-form-item label="公司名称6">
                    <el-input style="width: 550px;" v-model="form.enterprises6Name"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-col :span="6">
                    <el-form-item label="属性名称1">
                        <el-input v-model="form.enterprises6Attr1"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="属性值1">
                        <el-input v-model="form.enterprises6Value1"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="单位1">
                        <el-input v-model="form.enterprises6Unit1"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="6">
                    <el-form-item label="属性名称2">
                        <el-input v-model="form.enterprises6Attr2"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="属性值2">
                        <el-input v-model="form.enterprises6Value2"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="单位2">
                        <el-input v-model="form.enterprises6Unit2"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="6">
                    <el-form-item label="属性名称3">
                        <el-input v-model="form.enterprises6Attr3"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="属性值3">
                        <el-input v-model="form.enterprises6Value3"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="单位3">
                        <el-input v-model="form.enterprises6Unit3"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="6">
                    <el-form-item label="属性名称4">
                        <el-input v-model="form.enterprises6Attr4"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="属性值4">
                        <el-input v-model="form.enterprises6Value4"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="单位4">
                        <el-input v-model="form.enterprises6Unit4"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-form-item label="公司简介">
                    <el-input style="width: 670px;" type="textarea" v-model="form.enterprises6Desc"></el-input>
                </el-form-item>
            </el-row>
            <div class="line"></div>

            



            <!-- <el-row>
                <div class="sub-title">农业产业园区企业实时画面</div>
            </el-row>
            <el-row>
                <el-form-item label="画面名称1">
                    <el-input v-model="form.jidiVedio1Title"></el-input>
                </el-form-item>
                <el-form-item label="画面路径1">
                    <el-input style="width: 550px" v-model="form.jidiVedio1Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="画面名称2">
                    <el-input v-model="form.jidiVedio2Title"></el-input>
                </el-form-item>
                <el-form-item label="画面路径2">
                    <el-input style="width: 550px" v-model="form.jidiVedio2Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="画面名称3">
                    <el-input v-model="form.jidiVedio3Title"></el-input>
                </el-form-item>
                <el-form-item label="画面路径3">
                    <el-input style="width: 550px" v-model="form.jidiVedio3Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="画面名称4">
                    <el-input v-model="form.jidiVedio4Title"></el-input>
                </el-form-item>
                <el-form-item label="画面路径4">
                    <el-input style="width: 550px" v-model="form.jidiVedio4Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="画面名称5">
                    <el-input v-model="form.jidiVedio5Title"></el-input>
                </el-form-item>
                <el-form-item label="画面路径5">
                    <el-input style="width: 550px" v-model="form.jidiVedio5Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="画面名称6">
                    <el-input v-model="form.jidiVedio6Title"></el-input>
                </el-form-item>
                <el-form-item label="画面路径6">
                    <el-input style="width: 550px" v-model="form.jidiVedio6Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="画面名称7">
                    <el-input v-model="form.jidiVedio7Title"></el-input>
                </el-form-item>
                <el-form-item label="画面路径7">
                    <el-input style="width: 550px" v-model="form.jidiVedio7Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="画面名称8">
                    <el-input v-model="form.jidiVedio8Title"></el-input>
                </el-form-item>
                <el-form-item label="画面路径8">
                    <el-input style="width: 550px" v-model="form.jidiVedio8Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="画面名称9">
                    <el-input v-model="form.jidiVedio9Title"></el-input>
                </el-form-item>
                <el-form-item label="画面路径9">
                    <el-input style="width: 550px" v-model="form.jidiVedio9Url"></el-input>
                </el-form-item>
            </el-row> -->
        </el-form>
        <el-row class="ta_c">
            <el-button type="primary" style="min-width: 88px;" @click="onSaveInfo">保存</el-button>
        </el-row>


        <el-dialog :title="title" class="pop" width="500px" :visible.sync="dialog">
            <el-form  label-width="120px">
                <el-form-item label="公司名称" >
                     <el-input v-model="formData.companyName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="经度">
                    <el-input type="number" v-model="formData.xAxis" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="纬度">
                    <el-input type="number" v-model="formData.yAxis" autocomplete="off"></el-input>
                </el-form-item>
                <!-- <el-form-item label="公司位置">
                    <el-select v-model="formData.position">
                        <el-option v-for="(item,index) in posilist" :key="index" :value="item.key" :label="item.value"></el-option>

                    </el-select>
    
                </el-form-item> -->
                <el-form-item label="公司描述">
                    <el-input type="textarea" v-model="formData.remark" autocomplete="off"></el-input>
                </el-form-item>
                <div class="dialog-footer" style="text-align: right">
                    <el-button @click="dialog = false">取 消</el-button>
                    <el-button type="primary" @click="addSubmit">确 定</el-button>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>

export default {
    name: '',
    data(){
        return{
            form: {
                mp4: '',
            },
            posilist: [
                {key: 1, value: '左'},
                {key: 2, value: '右'},
            ],
            title: '新增',
            dialog: false,
            formData: {},
            tableData: []
        }
    },
    
    mounted(){
        this.search();
        this.getInfo()
    },
    methods:{
        search(){
            this.qa.datascreen15queryForList({}).then(res => {
                this.tableData = res.data;
            })
        },
        getInfo() {
            this.qa.datascreen15LoadDataBO({}).then(res => {
                if(res.respCode == '0000') {
                    this.form = res.data;
                }
            })
        },
        add(){
            this.formData = {};
            this.title = "新增";
            this.dialog = true;
        },
        edit(row){
            this.formData = JSON.parse(JSON.stringify(row));
            this.title = "修改";
            this.dialog = true;
        },
        remove(id){
            this.qa.datascreen15DelCompany({id: id}).then(res => {
                this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                    // this.dialog = false;
                    this.search()
            })
        },
        addSubmit() {
            if(this.title == '新增') {
                this.qa.datascreen15AddCompany(this.formData).then(res => {
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                    this.dialog = false;
                    this.search()
                })
            }else {
                //修改
                this.qa.datascreen15EditCompany(this.formData).then(res => {
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                    this.dialog = false;
                    this.search()
                })
            }
        },
        onSaveInfo() {
            this.qa.datascreen15DoSave(this.form).then(res => {
                if(res.respCode == '0000') {
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                    this.getInfo()
                }
            })
            
        },
        beforeAvatarUpload(file) {
            console.log(file.type)
            //const isJPG = file.type === 'image/jpeg';
            //const isLt2M = file.size / 1024 / 1024 < 10;

            // if (!isJPG) {
            //   this.$message.error('上传头像图片只能是 mp4 格式!');
            // }
            // if (!isLt2M) {
            //     this.$message.error('上传头像图片大小不能超过 10MB!');
            // }
            // return  isLt2M;
        },
        handleAvatarSuccess(res, file) {
            console.log(res)
            this.form.mp4 = res.data.url
        },
    }
}
</script>

<style scoped>
    .sub-title {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 20px;
    }
    .avatar-uploader {
        width: 150px;
        height: 162px;
        border: 1px dashed #ccc;
        text-align: center;
    }
    .avatar-uploader .avatar {
        width: 150px;
        height: 162px;
    }
    .line {
        width: 100%;
        height: 1px;
        margin: 30px 0;
        background: #ccc;
    }
</style>